@import '_variables';
@import '_mixins';

@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

body {
    main {
        .hero-image-wrapper.immersive-image-wrapper {
            background-color: #C68043;
            .arrow {
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -20px;
                width: 40px;
                height: 40px;
                opacity: .5;
            }
            .bounce {
                -moz-animation: bounce 3s infinite;
                -webkit-animation: bounce 3s infinite;
                animation: bounce 3s infinite;
            }
        }
        .content-wrapper {
            .numbers-wrapper {
                @include maciej_numbers;
                @include maciej_as_seen_on;
                .development-wrapper {
                    position: relative;
                    z-index: 10;
                    background-color: rgba(0, 0, 0, 0.6);
                    .posts {
                        display: flex;
                        align-items: baseline;
                        .post {
                            //padding: .8em 1em 1em;
                            padding: 1em;
                            a {
                                display: block;
                                color: $almostWhite;
                                .parent {
                                    color: $highlightYellow;
                                }
                            }
                        }
                        .section-title {
                            margin-right: 1em;
                            font-weight: bold;
                            //font-size: 1.2em;
                        }
                    }
                }
            }

            .posts-wrapper {
                .posts {
                    .list {
                        .post {
                            .text {
                                .parent {
                                    font-size: .6em;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;
                                }
                                .title {
                                    font-size: 1.1em;
                                }
                                .strapline {
                                    margin-top: 1em;
                                    height: 4.5em;
                                    overflow: hidden;
                                    font-size: .7em;
                                    line-height: 1.5em;
                                    color: $grey;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }

            .section-sport {
                padding-bottom: 3em;
            }

            .section-travel {
                margin-top: 0;
                padding: 3em 0;
                background: #f0f0f0 url(https://lh3.googleusercontent.com/selebGBhDJ0zLxPFSP3PNJCXQ9pMxrrHSJuHCOkvdWksvKtrtwowTdMCjbS9-zFe_i2AyTmpos6ulN_itj0=w1500-no-tmp.jpg) no-repeat center center;
                background-size: cover;
                background-attachment: fixed;
                .title-wrapper {
                    @include contentWidth;
                    h2.section-title {
                        margin-top: 0;
                    }
                }
            }

            .section-rocco {
                margin-bottom: 3em;
            }
            .posts-featured {
                z-index: 5;
                margin-bottom: 2em;
            }
            .posts-featured-small {
                margin-bottom: 1em;
            }

            .section-instagram {
                margin: 0;
                @include fullBleed;
                min-height: calc(12.5vw + 50px + 1em);
                background: transparent url(../../public/img/colorbox/spinner.gif) no-repeat center center;
                background-size: 30px 30px;
                font-size: .9em;
                &.section-instagram-moonlightrocco {
                    margin-top: 3em;
                }
                .users {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: .5em 0;
                    color: $almostBlack;
                    .user {
                        margin: 0 .5em;
                        .picture {
                            height: 50px;
                            padding-right: .4em;
                            img {
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }
                        }
                        a {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                .posts {
                    display: flex;
                    justify-content: space-between;
                    -webkit-justify-content: space-between;
                    .post {
                        width: 12.5%;
                        height: 12.5vw;
                        overflow: hidden;
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-color: $lightGrey;
                            background-position: center;
                            background-size: cover;
                            transition: all .5s;
                            &:focus,
                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }
}

body.hero {
    .image.hero {
        .hi {
            position: absolute;
            left: 8%;
            bottom: 8%;
            font-size: 2.4em;
            line-height: 1.2em;
            color: #fff;
            .pronunciation {
                display: block;
                color: $white;
                font-size: .8em;
                font-style: italic;
                opacity: .2;
                border: none;
            }
        }
    }
    .content-wrapper {
        margin-top: 100vh;
    }
    .header {
        padding: 1em;
        text-align: center;
        //background-color: $almostBlack;
        color: $black;
        font-size: 1.5em;

        background: #f0f0f0 url(https://lh3.googleusercontent.com/selebGBhDJ0zLxPFSP3PNJCXQ9pMxrrHSJuHCOkvdWksvKtrtwowTdMCjbS9-zFe_i2AyTmpos6ulN_itj0=w1500-no-tmp.jpg) no-repeat center center;
        background-size: cover;
        background-attachment: fixed;

        span {
            //color: $almostWhite;
            color: $black;
            //font-size: 1.1em;
        }
    }
    footer {
        margin-top: 0;
    }
}

